import React from 'react';
import { Header, Image } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import logo from '../assets/snsLogo.png'
import styles from './AppHeader.module.css';

export const AppHeader = React.memo(({ view, user }) => {
    return (
        <>
            <Link to="/">
                <Header className={styles.header} as="h1">
                    <Image style={{ width: 200 }} src={logo} alt="SHAKEN not STIRRED" />
                </Header>
            </Link>
        </>
    )
})
